@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.button {
		width: 100%;
		margin-top: 20px;

		@include tablet {
			width: 114px;
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include tablet {
			flex-direction: row;
		}

		.input {
			width: 100%;

			@include tablet {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
		}

		.inputWrapper {
			width: 100%;
			flex: 1;

			@include tablet {
				width: 50%;
				margin-right: 3px;
			}
		}
	}

	.error {
		padding-top: 16px;
		font-size: 16px;
	}
}
