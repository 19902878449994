@import "~/styles/mixins.scss";
.text {
	margin-top: 17px;
	line-height: 20px;

	@include tablet {
		margin-top: 33px;
		max-width: 480px;
	}
}
