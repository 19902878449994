@import "~/styles/mixins.scss";
.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: 1px solid $white;
	margin-top: 24px;
	transition: all 0.1s ease;
	height: min(530px, 125vw);

	@include mobile {
		height: min(460px, 75vw);
	}

	@include tablet {
		height: min(600px, 65vw);
	}

	@include small-desktop {
		height: min(560px, 45vw);
	}

	&:before {
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		border: 1px solid $black10;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
	}

	&:hover {
		border: 1px solid $navy;

		&:before {
			border-color: $navy;
		}
	}
}
