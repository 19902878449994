@import "~/styles/mixins.scss";
.wrapper {
	top: 1px;
	left: 1px;
	width: calc(100% - 2px);
	display: block;

	> span {
		// next/image height auto fix
		position: unset !important;
	}

	.image {
		object-fit: contain;
		object-position: top;
		width: 100% !important;
		height: unset !important;
		padding: 1px;
	}

	.iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		.icon {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 53px;
				left: 50px;
				width: 30px;
				height: 30px;
				background-color: $white;
				z-index: -1;
			}
		}
	}
}
