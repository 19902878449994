@import "~/styles/mixins.scss";
.wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;

	@include tablet {
		padding: 30px;
	}

	.title {
		font-size: 16px;
		font-weight: 600;
		min-height: 48px;
		color: $navy;
		display: inline-block;

		@include tablet {
			font-size: 18px;
		}

		&:hover {
			color: $green;
		}
	}

	.text {
		font-size: 14px;
		min-height: 40px;
		color: $black55;
		margin: 4px 0;
		line-height: 20px;
		/* stylelint-disable-next-line value-no-vendor-prefix */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;

		@include tablet {
			margin: 8px 0 24px;
		}
	}

	.hashTags {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		color: $navy;
		font-size: 14px;
		line-height: 20px;

		.element {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.tagList {
		height: 48px;
		column-gap: 8px;
		display: flex;
		flex-flow: row wrap;
		overflow: hidden;
		margin-bottom: 16px;

		.tag {
			color: $navy;
		}
	}
}
