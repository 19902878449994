@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: 100%;
	padding: 16px 0;
	border-bottom: 1px solid $black10;

	.dateTime {
		width: 30%;
		padding-left: 5px;
	}

	.type {
		width: 25%;
	}

	.status,
	.amount,
	.accountBalance {
		width: 15%;
	}
}
