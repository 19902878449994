@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;
	color: $black65;
	line-height: 24px;

	:global {
		h1,
		h2,
		h3,
		h4 {
			font-weight: 600;
		}

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 18px;
			margin: 20px 0 10px;
		}

		h3 {
			font-size: 16px;
		}

		h4,
		h5 {
			font-size: 14px;
		}

		h6 {
			font-size: 12px;
		}

		ul {
			padding: 16px 0;

			li {
				position: relative;
				padding-left: 15px;
				margin-bottom: 3px;
				line-height: 23px;

				&:before {
					position: absolute;
					content: "";
					border-color: transparent $green;
					border-style: solid;
					border-width: 7px 0 7px 7px;
					display: block;
					height: 0;
					width: 0;
					left: 0;
					top: 6px;
				}
			}
		}
	}

	.loader {
		margin: 50px auto;
	}
}
