@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.form {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 30px 0;

		@include small-desktop {
			flex-direction: row;
		}
	}
}
