@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: center;
	width: 100%;

	&:before {
		content: "";
		margin-top: 15px;
		height: 38px;
		width: 50%;
		background-color: $green-light-bow-adornment;
	}

	&:after {
		content: "";
		margin-top: 15px;
		height: 38px;
		width: 50%;
		background-color: $green-light-bow-adornment;
	}

	.image {
		width: 298px;
		height: 129px;
	}

	.line {
		height: 38px;
		width: 100%;
		background-color: $green-light-bow-adornment;
	}

	&.isMozaic {
		&:before,
		&:after {
			background-color: $color-primary-01-600;
		}

		.line {
			background-color: $color-primary-01-600;
		}
	}
}
